import "./App.css";
function App() {
  return (
    <div id="pivot-main">
      <h1 id="pivot-h1">Pivot</h1>
    </div>
  );
}

export default App;
